.show-loading-animation {
  &.rect-shape,
  &.round-shape,
  &.text-row,
  .rect-shape,
  .round-shape,
  .text-row {
    animation: react-placeholder-pulse 1.5s infinite;
  }
}


@keyframes react-placeholder-pulse {
  0% {
    opacity: .6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .6;
  }
}
